@import "variables.scss";
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "core/palette.scss";
@import "app-variables.scss";
@import "mixins.scss";
@import "custom-bootstrap.scss";
@import "core.scss";
@import "plugins.scss";
@import "customizer.scss";
// @import "demo.scss"; // Remove this SCSS from production

.filter-section {
  color: theme-color("dark");
  line-height: 2rem;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  border-bottom: 1px solid theme-color("dark");
}
